/* eslint-disable indent */
import HttpService from '@pingum/api-services/HttpService';

export default class HorseLocationService {
  static getHorseLocations(page, size, sort, date, endDate) {
    return this.httpService.get(`?page=${page}&size=${size}${sort}&timecheck=${date
      ? date.toISOString()
      : ''}${endDate
        ? `&timecheckEnd=${endDate.toISOString()}`
        : ''}`);
  }
}

HorseLocationService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/horseLocations`,
});

