/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { groupBy } from 'lodash';
import { primary } from './brandColors';

export const getDayOfWeekFromNumber = (day) => {
  if (day === 0) return 'Sunday';
  if (day === 1) return 'Monday';
  if (day === 2) return 'Tuesday';
  if (day === 3) return 'Wednesday';
  if (day === 4) return 'Thursday';
  if (day === 5) return 'Friday';
  return 'Saturday'
}

// function mostUsedAssignment(arr) {
//   return arr.filter((a) => !!a).sort((a, b) => arr.filter((v) => v === a).length
//       - arr.filter((v) => v === b).length).pop();
// }

/*
  Parses the raw string task assignment into an array of barn users who are assigned
  * currently only returns partial objects, add more fields as needed
*/
export const getAssignmentsFromTaskRawAssignment = (rawAssignment, barnUsersMap) => (rawAssignment || '').split('@[').filter((a) => !!a).map((a) => {
  const auth0UserId = a.substring(a.indexOf('](') + 2, a.length - 1) === 'null'
    ? a.substring(0, a.indexOf(']('))
    : a.substring(a.indexOf('](') + 2, a.length - 1);
  const barnUser = (barnUsersMap[a.substring(a.indexOf('](') + 2, a.length - 1)] && barnUsersMap[a.substring(a.indexOf('](') + 2, a.length - 1)][0]) || null;
  return {
    userFullName: a.substring(0, a.indexOf('](')),
    auth0UserId,
    email: (barnUser && barnUser.email) || null,
    avatar: (barnUser && barnUser.avatar) || `https://i0.wp.com/cdn.auth0.com/avatars/${(a.substring(0, a.indexOf('](')) || 'U').charAt(0).toLowerCase()}.png`,
    id: (barnUser && barnUser.id) || null,
  };
});

/*
  Generate the board items from the created tasks, schedule occurrencee events, and metadata
*/
export const getBarnBoard = (todaysTasks, events, incompleteTasks, barnUsersMap, locationTagId, barnMetadata, statuses) => {
  const endOfTodayTimestamp = moment().endOf('day').toDate().getTime();
  const tasks = [];
  const schedules = [];
  const { horseTagTypeId } = barnMetadata;
  const customStatusIds = statuses
    .filter((s) => s.id !== barnMetadata.statusStartId && s.id !== barnMetadata.statusPendingId && s.id !== barnMetadata.statusEndId)
    .map((s) => s.id);
  /* STEP 1
     Add tasks created today, map to the corresponding schedule event (if found)
  */
  for (let i = 0; i < todaysTasks.length; i += 1) {
    const event = events.find((e) => (e.start.getTime() - (e.resource.advanceNotice || 0)) === moment(todaysTasks[i].startDate).toDate().getTime()
      && e.resource.id === todaysTasks[i].scheduleId) || {
      start: new Date(todaysTasks[i].startDate),
      color: (todaysTasks[i].eventData.Color && todaysTasks[i].eventData.Color.value) || primary.main,
    }
    const eventTemplateForTask = event.resource && event.resource.eventTemplate.children.find((et) => et.id === todaysTasks[i].eventTemplateId);
    tasks.push({
      key: `today-task-${todaysTasks[i].id}-${i}`,
      title: todaysTasks[i].name,
      taskTitle: (eventTemplateForTask && eventTemplateForTask.name) || todaysTasks[i].name,
      task: todaysTasks[i],
      workflow: null,
      schedule: null,
      event,
      sortDateTimestamp: todaysTasks[i].completeDate
        ? new Date(todaysTasks[i].completeDate + 86400000).getTime() // we want complete events to sort at bottom of lists (adding 24 hours)
        : new Date(todaysTasks[i].startDate).getTime(),
      assignments: getAssignmentsFromTaskRawAssignment((todaysTasks[i].currentEventStatus && todaysTasks[i].currentEventStatus.assignment) || '', barnUsersMap),
      participants: getAssignmentsFromTaskRawAssignment((todaysTasks[i].scheduleAssignment) || '', barnUsersMap),
      taskTypeId: (todaysTasks[i].eventTemplateId && parseInt(todaysTasks[i].eventTemplateId, 10)) || null,
      customStatus: todaysTasks[i].currentEventStatus && todaysTasks[i].currentEventStatus.status
        && customStatusIds.indexOf(todaysTasks[i].currentEventStatus.status.id) >= 0 && todaysTasks[i].currentEventStatus.status,
    });
  }

  /* STEP 2
     Add tasks created before today not yet complete, map to the corresponding schedule event (if found)
  */
  for (let i = 0; i < incompleteTasks.length; i += 1) {
    tasks.push({
      key: `incomplete-task-${incompleteTasks[i].id}-${i}`,
      title: incompleteTasks[i].name,
      task: incompleteTasks[i],
      taskTitle: incompleteTasks[i].name, // Prior day task so we don't the schedule(event) to grab the workflow name of
      workflow: null,
      schedule: null,
      event: {
        start: new Date(incompleteTasks[i].startDate),
        end: new Date(incompleteTasks[i].startDate),
        color: (incompleteTasks[i].eventData.Color && incompleteTasks[i].eventData.Color.value) || primary.main,
        resource: {
          id: incompleteTasks[i].scheduleId,
          eventTemplate: { id: incompleteTasks[i].eventTemplateId },
        },
      }, // since this is prior day we won't have exact event/schedule
      sortDateTimestamp: incompleteTasks[i].completeDate
        ? new Date(incompleteTasks[i].completeDate + 86400000).getTime() // we want complete events to sort at bottom of lists
        : new Date(incompleteTasks[i].startDate).getTime(),
      assignments: getAssignmentsFromTaskRawAssignment((incompleteTasks[i].currentEventStatus && incompleteTasks[i].currentEventStatus.assignment) || '', barnUsersMap),
      participants: getAssignmentsFromTaskRawAssignment((incompleteTasks[i].scheduleAssignment) || '', barnUsersMap),
      taskTypeId: (incompleteTasks[i].eventTemplateId && parseInt(incompleteTasks[i].eventTemplateId, 10)) || null,
      customStatus: incompleteTasks[i].currentEventStatus && incompleteTasks[i].currentEventStatus.status
        && customStatusIds.indexOf(incompleteTasks[i].currentEventStatus.status.id) >= 0 && incompleteTasks[i].currentEventStatus.status,
    });
  }

  /* STEP 3
     Loop over all questri schedule occurrence events for today an generate board items for each
  */
  for (let i = 0; i < events.length; i += 1) {
    const scheduleTaskStartTime = moment(new Date(events[i].start.getTime() - events[i].resource.advanceNotice || 0)).toDate();
    const tasksAlreadyCreated = moment(new Date()).toDate().getTime() > scheduleTaskStartTime.getTime();
    const workflows = events[i].barnTask
      // if its a barn task schedule only include workflows from board location
      ? events[i].resource.workflows.filter((w) => w.tagIds.indexOf(parseInt(locationTagId, 10)) >= 0)
      : events[i].resource.workflows;

    /*
      If a schedule is of horse or barn task type and has more than one workflow, we group them
    */
    const group = [];
    if ((events[i].horseTask || events[i].barnTask) && workflows && workflows.length > 1) {
      // This schedule has multiple of the same workflow using the same event template, let's get data
      for (let j = 0; j < workflows.length; j += 1) {
        if (!workflows[j].createEvents || !tasksAlreadyCreated) {
          // use the schedule workflows since these are not created yet
          group.push({ workflow: workflows[j] });
        }
      }
      // now find any generated tasks mapped to schedule and time (if already created)
      if (tasksAlreadyCreated && workflows.filter((w) => w.createEvents).length > 0) {
        group.concat(todaysTasks
          .filter((t) => t.scheduleId === events[i].resource.id && t.eventTemplateId === events[i].resource.eventTemplate.id
            && new Date(t.startDate).getTime() === events[i].start.getTime()).map((t) => ({ task: t })));
      }
    }

    // first add to the schedule list, mapping participants
    schedules.push({
      key: `schedule-${events[i].resource.id}-${events[i].start.getTime()}-${i}`,
      event: events[i],
      clientParticipants: events[i].participants
        .filter((u) => (u && u.roles && u.roles.indexOf('owner') < 0 && u.roles.indexOf('admin') < 0 && u.roles.indexOf('staff') < 0)) // remove staff
        .filter((v, j, a) => a.findIndex((v2) => (v2.auth0UserId === v.auth0UserId)) === j), // remove duplicates
      group: (group && group.length > 0)
        ? group
        : null,
    });

    // add each workflow in the schedule as a task
    for (let j = 0; j < (workflows || []).length; j += 1) {
      // only add if the workflow is in the future or if it is not a generated Pingum event task
      if (!workflows[j].createEvents || !tasksAlreadyCreated) {
        const workflowName = (events[i].resource.eventTemplate.children.find((et) => et.id === workflows[j].eventTemplateId)
          || { name: events[i].resource.name }).name
        tasks.push({
          key: `schedule-workflow-${events[i].resource.id}-${events[i].start.getTime()}-${i}-${j}`,
          title: workflowName === events[i].resource.name
            ? workflowName
            : `${workflowName} (${events[i].resource.name})`,
          taskTitle: workflowName,
          task: null,
          workflow: workflows[j],
          schedule: events[i].resource,
          event: events[i],
          sortDateTimestamp: events[i].allDay
            ? endOfTodayTimestamp // all day tasks should fall after tasks with a specific time in the day, so push to end of day
            : events[i].start.getTime(),
          assignments: getAssignmentsFromTaskRawAssignment(workflows[j].startingAssignment || '', barnUsersMap),
          participants: getAssignmentsFromTaskRawAssignment((workflows[j].scheduleAssignment || (events[i].resource && events[i].resource.assignment)) || '', barnUsersMap),
          taskTypeId: parseInt(workflows[j].eventTemplateId, 10),
          customStatus: workflows[j].startingStatusId && customStatusIds.indexOf(workflows[j].startingStatusId) >= 0
            && statuses.find((s) => s.id === workflows[j].startingStatusId),
        });
      }
    }
  }

  const userTasks = { unassigned: [] };
  let locationTasks = {};
  const horseTasks = {};
  let taskTypeTasks = {};
  tasks.sort((a, b) => moment(a.event.start).unix() - moment(b.event.start).unix()).forEach((task) => {
    if (task.assignments && task.assignments.length > 0) {
      task.assignments.forEach((assignment) => {
        if (!userTasks[assignment.auth0UserId]) {
          userTasks[assignment.auth0UserId] = [];
        }
        userTasks[assignment.auth0UserId].push(task);
      });
    } else {
      userTasks.unassigned.push(task);
    }
  });

  locationTasks = groupBy(tasks.map((t) => ({
    ...t,
    event: {
      ...t.event,
      resource: {
        ...t.event.resource,
        location: (t.event.resource && t.event.resource.location) || 'No Specified Location',
      },
    },
  })), 'event.resource.location')
  taskTypeTasks = groupBy(tasks.map((t) => ({
    ...t,
    event: {
      ...t.event,
      resource: {
        ...t.event.resource,
        location: (t.event.resource && t.event.resource.location) || 'No Specified Location',
      },
    },
  })), 'event.resource.eventTemplate.id')
  if (horseTagTypeId) {
    for (let i = 0; i < tasks.length; i += 1) {
      let horseTag = null;
      if (tasks[i].workflow && tasks[i].schedule && tasks[i].schedule.tags.find((t) => t.tagType.id === horseTagTypeId)) {
        // Horse tag should be the tag in the workflow
        horseTag = tasks[i].workflow.tagIds && tasks[i].workflow.tagIds.length > 0 && {
          id: tasks[i].workflow.tagIds[0],
          tagType: { id: horseTagTypeId },
        }
      } else if (tasks[i].task) {
        // Only a task, no schedule
        // find horse tag
        horseTag = tasks[i].task.tags.find((t) => t.tagType.id === horseTagTypeId);
      }
      if (horseTag) {
        if (!horseTasks[horseTag.id]) {
          horseTasks[horseTag.id] = [];
        }
        horseTasks[horseTag.id].push(tasks[i]);
      }
    }
  }

  return {
    tasks: tasks.sort((a, b) => moment(a.event.start).unix() - moment(b.event.start).unix()),
    userTasks,
    locationTasks,
    horseTasks,
    taskTypeTasks,
    schedules: schedules.sort((a, b) => moment(a.event.start).unix() - moment(b.event.start).unix()),
  };
};

export const getSize = (size) => {
  if (size === 'l') return 40;
  if (size === 'xl') return 50;
  return 32
}

export const getBoardItemCaptionSize = (size) => {
  if (size === 'l') return 14;
  if (size === 'xl') return 17;
  return 12
}

export const getBoardItemAvatarSpacing = (size) => {
  if (size === 'l') return 8;
  if (size === 'xl') return 12;
  return 6
}

export const getBoardActionIconFontSize = (size) => {
  if (size === 'l') return 22;
  if (size === 'xl') return 28;
  return 18
}

export const getTitleFontVariant = (size) => {
  if (size === 'l') return 'h5';
  if (size === 'xl') return 'h4';
  return 'body1'
}

export const getCardWidth = (size) => {
  if (size === 'l') return 375;
  if (size === 'xl') return 500;
  return 300
}

export const getChipSize = (size) => {
  if (size === 'l') return 'medium';
  if (size === 'xl') return 'large';
  return 'small'
}

export const getBoardColorLineMargin = (size) => {
  if (size === 'l') return '14px';
  if (size === 'xl') return '16px';
  return '12px'
}

export const getBoardAgendaIconFontSize = (size) => {
  if (size === 'l') return 36;
  if (size === 'xl') return 42;
  return 32
}

export const getBoardAgendaTitleFontSize = (size) => {
  if (size === 'l') return 28;
  if (size === 'xl') return 36;
  return 22
}

export const getBoardAgendaFieldFontSize = (size) => {
  if (size === 'l') return 16;
  if (size === 'xl') return 20;
  return 14
}

export const getBoardAgendaFieldIconFontSize = (size) => {
  if (size === 'l') return 20;
  if (size === 'xl') return 24;
  return 18
}

export const getBoardAgendaTimeFontSize = (size) => {
  if (size === 'l') return 20;
  if (size === 'xl') return 24;
  return 18
}

const dayIsInRange = (dayKey, suggestionsStartDay, boardStartDate, boardEndDate, weekStyle) => {
  const suggestionStartDateTimestamp = suggestionsStartDay.unix() * 1000;
  if (weekStyle === 'week') {
    return (suggestionStartDateTimestamp < boardEndDate.getTime()) && dayKey >= suggestionsStartDay.day();
  }

  if (suggestionsStartDay.day() === dayKey) {
    // Always show on the suggested start date if not in past amd before 10am
    return true;
  }
  if (boardStartDate.getTime() > suggestionsStartDay.toDate().getTime()) {
    // We are looking at planning week in future so we can show all days
    return true
  }
  if (boardEndDate.getTime() <= suggestionsStartDay.toDate().getTime()) {
    // We never show suggestions in the past
    return false;
  }

  // We are falling in the middle of a week so only return days after suggestion start date
  return (suggestionsStartDay.day() !== 0 && (dayKey === 0
    || dayKey > suggestionsStartDay.day()))
}

const getDayTimestampForPlanningWeek = (day, boardStartDate, boardEndDate) => {
  for (let m = moment(boardStartDate); m.isSameOrBefore(boardEndDate); m.add(1, 'days')) {
    if (day === m.day()) return m.toDate().getTime(); // day in range, return timestamp
  }
  return 0;
}

const getDaySuggestionInfo = (day, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay) => {
  const alreadyPlannedTasks = (overview[horseTagId][getDayTimestampForPlanningWeek(day, boardStartDate, boardEndDate)] || [])
    .map((e) => e.event && e.event.resource && e.event.resource.eventTemplate.id).filter((e) => e !== undefined).map((t) => `${t}`)

  /*
    We only show suggestions if
    - Suggestions not hidden
    - It is 10am or earlier day of, or a day in future
    - There is a task used 15 times in the last month (task not in hidden list)
    - There is a task used 3/5 the previous 5 days (task is not in hidden list)
    - There is a task used at least twice on this day the last month (task not in hidden list)
  */

  const shouldShowSuggestions = suggestion && !suggestion.hideAllSuggestions
    && dayIsInRange(day, suggestionsStartDay, boardStartDate, boardEndDate, weekStyle);

  const suggestMonthlyTrend = shouldShowSuggestions && suggestion && suggestion.byTaskType && Object.keys(suggestion.byTaskType)
    .filter((key) => alreadyPlannedTasks.indexOf(key) < 0 && suggestion.byTaskType[key].length >= 15);

  const suggestRecentTrend = shouldShowSuggestions && suggestion && suggestion.lastFiveDaysTrend && Object.keys(suggestion.lastFiveDaysTrend)
    .filter((key) => alreadyPlannedTasks.indexOf(key) < 0 && suggestion.lastFiveDaysTrend[key].length >= 3);

  const dayOfWeekTasks = shouldShowSuggestions && suggestion && suggestion.byDayOfWeek && suggestion.byDayOfWeek[day] && groupBy(suggestion.byDayOfWeek[day], 'taskTypeId');
  const suggestDayTrend = dayOfWeekTasks && Object.keys(dayOfWeekTasks)
    .filter((key) => alreadyPlannedTasks.indexOf(key) < 0 && dayOfWeekTasks[key].length >= 2);

  return {
    alreadyPlannedTasks,
    suggestMonthlyTrend,
    suggestRecentTrend,
    suggestDayTrend,
    allSuggestions: [...(suggestMonthlyTrend || []), ...(suggestRecentTrend || []), ...(suggestDayTrend || [])],
  }
}

export const generateBarnBoardSuggestions = (
  barnHorses,
  overview,
  mappedHorseSuggestions,
  upcomingDueDates,
  boardStartDate,
  boardEndDate,
) => {
  const weekStyle = window.localStorage.getItem('planning_week_style') || 'isoWeek';
  const mappedBoardHorses = { hasSuggestions: upcomingDueDates.filter((tagReminder) => (tagReminder.status === 'Unscheduled' || tagReminder.status === 'PastDue')).length > 0 };

  const suggestionsStartDay = moment().hour() < 9
    ? moment()
    : moment().add(1, 'days'); // Day of week to start suggesting planning. if before 10am, we can suggest today
  const weekInPast = boardEndDate.getTime() < new Date().getTime()
  for (let i = 0; i < barnHorses.length; i += 1) {
    const horseTagId = parseInt(barnHorses[i].pingumTagId, 10);
    const suggestion = mappedHorseSuggestions[horseTagId];
    mappedBoardHorses[barnHorses[i].pingumTagId] = {
      barnHorse: barnHorses[i],
      dueDates: upcomingDueDates.filter((tagReminder) => tagReminder.tag.id === parseInt(barnHorses[i].pingumTagId, 10) && (tagReminder.status === 'Unscheduled' || tagReminder.status === 'PastDue')),
      suggestion,
      0: !weekInPast && getDaySuggestionInfo(0, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      1: !weekInPast && getDaySuggestionInfo(1, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      2: !weekInPast && getDaySuggestionInfo(2, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      3: !weekInPast && getDaySuggestionInfo(3, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      4: !weekInPast && getDaySuggestionInfo(4, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      5: !weekInPast && getDaySuggestionInfo(5, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
      6: !weekInPast && getDaySuggestionInfo(6, horseTagId, overview, boardStartDate, boardEndDate, suggestion, weekStyle, suggestionsStartDay),
    }
  }

  return mappedBoardHorses;
}

export const top5MostFrequent = (horseSuggestions) => {
  let taskTypeIdOccurrences = []
  for (let i = 0; i < horseSuggestions.length; i += 1) {
    taskTypeIdOccurrences = taskTypeIdOccurrences.concat(horseSuggestions[i].recentTaskRecords.map((hs) => hs.taskTypeId))
  }

  const counts = {};
  for (const num of taskTypeIdOccurrences) {
    counts[num] = (counts[num] || 0) + 1;
  }

  const sortedCounts = Object.entries(counts).sort(([, countA], [, countB]) => countB - countA);
  const top5 = sortedCounts.slice(0, 5).map(([num]) => parseInt(num, 10));
  return top5;
}

export const getMappedHorseSuggestions = (horseSuggestions) => {
  const groupedSuggestions = groupBy(horseSuggestions, 'pingumTagId');
  const keys = Object.keys(groupedSuggestions);
  for (let i = 0; i < keys.length; i += 1) {
    const suggestion = groupedSuggestions[keys[i]] && groupedSuggestions[keys[i]][0];
    const tasks = ((suggestion && suggestion.recentTaskRecords
      && suggestion.recentTaskRecords.filter((t) => (suggestion.hiddenTaskSuggestions || []).indexOf(t.taskTypeId) < 0)) || [])
      .map((task) => ({
        ...task,
        dayOfWeek: moment(task.taskDate).day(),
        assignments: getAssignmentsFromTaskRawAssignment(task.assignment, {}).map((u) => `@[${u.userFullName}](${u.auth0UserId})`),
        participants: getAssignmentsFromTaskRawAssignment(task.participant, {}).map((u) => `@[${u.userFullName}](${u.auth0UserId})`),
      }))

    if (!suggestion || suggestion.hideAllSuggestions || !tasks || tasks.length === 0) {
      // Nothing to suggestion
      groupedSuggestions[keys[i]] = null;
    }

    // break each assignment into a task for counting
    const tasksByAssignment = []
    for (let o = 0; o < tasks.length; o += 1) {
      for (let j = 0; j < tasks[o].assignments.length; j += 1) {
        tasksByAssignment.push({
          ...tasks[o],
          assignment: tasks[o].assignments[j],
        })
      }
    }

    // break each participant into a task for counting
    const tasksByParticipant = []
    for (let o = 0; o < tasks.length; o += 1) {
      for (let j = 0; j < tasks[o].participants.length; j += 1) {
        tasksByParticipant.push({
          ...tasks[o],
          participant: tasks[o].participants[j],
        })
      }
    }

    groupedSuggestions[keys[i]] = {
      suggestionId: suggestion.id,
      hideAllSuggestions: suggestion.hideAllSuggestions,
      hiddenTaskSuggestions: suggestion.hiddenTaskSuggestions,
      byAssignment: groupBy(tasksByAssignment, 'assignment'),
      byParticipant: groupBy(tasksByParticipant, 'participant'),
      byTaskType: groupBy(tasks, 'taskTypeId'),
      byDayOfWeek: groupBy(tasks, 'dayOfWeek'),
      lastFiveDaysTrend: groupBy(tasks.filter((t) => (moment(t.taskDate).toDate().getTime() > moment().add(-5, 'days').toDate().getTime())), 'taskTypeId'),
    }
  }

  return groupedSuggestions;
}
