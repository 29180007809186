import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  includes,
  values,
  mapValues,
} from 'lodash';
import { addTenant as addTenantAction } from '@pingum/app-state-management/tenant/actions';
import {
  TenantService,
  AuthService,
} from '@pingum/api-services';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Check from '@mui/icons-material/Check';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';

import {
  Divider,
  MenuItem,
  Select,
} from '@mui/material';
import Tenant from '../../Tenant';
import AvatarEditor from '../../AvatarEditor';
import PricingPlans from '../../PricingPlans';
import startBuilding from '../../../images/unused-undraw-illustrations/undraw_start_building_re_xani.svg';
import { primary } from '../../../utils/brandColors';
import FAQCard from '../../../pages/settings/AdminSettingsPage/components/FAQCard';

const defaultTenant = {
  displayName: '',
  name: '',
  tenantUrl: '',
  icon: null,
  iconColor: primary.main,
  stripePlan: env.stripe_basic_monthly_plan,
  stripeFreeTrial: true,
  stripeAddCardToken: null,
  userLimit: 0,
  application: 'questri',
};

const defaultValidation = {
  hasChanged: false,
  name: {
    isValid: true,
    message: '',
  },
  displayName: {
    isValid: true,
    message: '',
  },
};
const imageContainer = {
  display: 'block',
  maxHeight: '200px',
  width: '100%',
  margin: 'auto',
};

const getTenantUrl = (name) => (name.length > 0
  ? TenantService.getTenantUrl(name)
  : '');

const CreateOrganizationComponent = ({ onCancel }) => {
  const dispatch = useDispatch();
  const avatarEditorRef = React.createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // redux state
  const { isAddingTenant: isLoading } = useSelector((state) => state.tenant);

  // local state
  const [showPricing, setShowPricing] = React.useState(false);
  const [billing, setBilling] = React.useState('monthly');
  const [loading, setLoading] = React.useState(false);
  const [nameTakenError, setNameTakenError] = React.useState(null);
  const [validation, setValidation] = React.useState(defaultValidation);
  const [tenant, setTenant] = React.useState(defaultTenant);
  const [howDidYouHear, setHowDidYouHear] = React.useState('Tell us how you heard about Questri!');
  const [howDidYouHearOther, setHowDidYouHearOther] = React.useState('');
  const [numberOfHorses] = React.useState(10);

  const isFetching = isLoading || loading;

  const addTenant = async () => {
    if (!(includes(values(mapValues(validation, (val) => {
      if (val instanceof Object) {
        return val.isValid;
      }
      return val;
    })), false))) {
      setLoading(true);
      // First call api to create tenant
      dispatch(addTenantAction(tenant)).then((newTenant) => {
        // place a small timeout on this request so we don't get unauthorized errrors
        setTimeout(() => {
          // now add the Questri barn
          const instance = axios.create({
            baseURL: `${env.REACT_APP_HORSE_API}/barns`,
            headers: {
              'content-type': 'application/json',
              'X-Tenant': newTenant.name,
              'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              authorization: `Bearer ${AuthService.getToken()}`,
            },
          });
          instance.post('/', {
            stripePlan: tenant.stripePlan,
            displayName: newTenant.displayName,
            signUpPlatform: 'web',
            howDidYouHear: howDidYouHear === 'Other'
              ? (howDidYouHearOther || 'Other - not specified')
              : howDidYouHear,
            numberOfHorses: numberOfHorses || 10,
          }).then(() => {
            window.location.href = `${window.location.protocol}//${newTenant.name}.${window.location.host}/onboarding`;
            setLoading(false);
          }).catch(() => {
            setLoading(false);
            setNameTakenError('There was an error creating your Questri Barn, please contact support.');
          });
        }, 1000)
      }).catch((err) => {
        if (err && err.message && err.message.indexOf('The name provided for this tenant is already taken') >= 0) {
          setNameTakenError(`${tenant.name} is already taken as a short name.`);
        }
        setLoading(false);
      });
    }
  };

  const handleNameChange = (e) => {
    const name = TenantService.normalizeName(e.target.value);
    const tenantUrl = getTenantUrl(name);
    const isValid = TenantService.isNameValid(name);

    setTenant({
      ...tenant,
      name,
      tenantUrl,
    });
    setValidation({
      ...validation,
      hasChanged: true,
      name: {
        isValid,
        message: !isValid
          ? 'Please provide a valid name'
          : '',
      },
    });
    setNameTakenError(false); // reset
  };

  const handleDisplayNameChange = (e) => {
    const displayName = e.target.value;
    const name = TenantService.normalizeName(displayName);
    const tenantUrl = getTenantUrl(name);
    const isValid = TenantService.isDisplayNameValid(displayName);

    setTenant({
      ...tenant,
      displayName,
      name,
      tenantUrl,
    });
    setValidation({
      ...validation,
      hasChanged: true,
      displayName: {
        isValid,
        message: !isValid
          ? 'Please provide a valid display name'
          : '',
      },
    });
    setNameTakenError(false); // reset
  };

  const handleAvatarChange = (icon) => {
    setTenant({
      ...tenant,
      icon,
      avatar: icon,
    });
    if (!icon) {
      avatarEditorRef.current.onCancel();
    }
  }

  const isValid = () => validation.hasChanged
    && !nameTakenError
    && validation.displayName.isValid
    && validation.name.isValid;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={10} md={8} lg={9} xl={10}>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  mb: 2,
                  fontSize: 32,
                }}
                color="inherit"
              >
                Create New Questri Barn
              </Typography>
              <Typography
                component="div"
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: 300,
                }}
                color="inherit"
              >
                Ready to experience the best horse management and equestrial planning app?.
              </Typography>
              <Typography
                component="div"
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: 400,

                }}
                color="inherit"
              />
              <Typography
                component="div"
                variant="subtitle2"
                sx={{ fontWeight: 300 }}
                color="inherit"
              >
                All new Questri Barns start with a <b>14 day free trial</b>. No
                credit card is required to sign up and use Questri during the trial period.
                <br />
                After the 14 day free trial, you can provide a credit card or bank account to
                continue using Questri.
              </Typography>
            </Grid>
            <Grid
              item
              xs={0}
              sm={2}
              md={4}
              lg={3}
              xl={2}
              sx={{ pl: 2 }}
            ><img src={startBuilding} alt="man access denied app" style={imageContainer} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  Create Your Questri Barn
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!validation.displayName.isValid}
                  fullWidth
                  aria-describedby="tenant-display-name-helper-text"
                  variant="outlined"
                  id="org-name"
                  label="Questri Barn Name"
                  value={tenant.displayName}
                  disabled={isFetching}
                  onChange={handleDisplayNameChange}
                  helperText={!validation.displayName.isValid
                    ? validation.displayName.message
                    : 'This is the name you will see in Questri.'}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  error={!validation.name.isValid && validation.name.message}
                  fullWidth
                  aria-describedby="tenant-name-helper-text"
                  variant="outlined"
                  id="short-name"
                  label="Questri Barn Short Name"
                  value={tenant.name}
                  disabled={isFetching}
                  onChange={handleNameChange}
                  helperText={validation.name.isValid
                    ? tenant.tenantUrl || 'Used to generate your unique website URL'
                    : validation.name.message}
                />
              </Grid>
            </Grid>
            {
              isValid() && (
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        Preview
                      </Typography>
                      <Typography variant="caption" gutterBottom>
                        How your Questri Barn appears to others
                      </Typography>
                      <Tenant tenant={tenant} />
                    </Box>
                    <AvatarEditor
                      ref={avatarEditorRef}
                      onChange={handleAvatarChange}
                      title="Upload Your Company Logo"
                    />
                  </Box>
                </Grid>
              )
            }
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2} sx={{ p: 2 }}>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 400,
                      mb: 1,
                    }}
                  >
                    Get Started Now
                  </Typography>
                  <Chip
                    label="14 Day Free Trial!"
                    color="success"
                    size="small"
                    deleteIcon={<Check />}
                    onDelete={() => { }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 300,
                    fontSize: 16,
                  }}
                >
                  Once your Questri Barn is setup, you will be able to add horses,
                  plan your tasks, customize your digital barn board, and invite your
                  team to unlock the best horse management tool out there.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      mt: 2,
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormLabel id="demo-form-control-label-placement">Select a trial plan</FormLabel>
                    <Button startIcon={<InfoOutlined />} variant="outlined" size="small" onClick={() => setShowPricing(true)}>View Plans</Button>
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    value={tenant.stripePlan}
                    onChange={(e) => setTenant({
                      ...tenant,
                      stripePlan: e.target.value,
                    })}
                  >
                    <FormControlLabel
                      value={billing === 'monthly'
                        ? env.stripe_basic_monthly_plan
                        : env.stripe_basic_annual_plan}
                      control={<Radio />}
                      label="Pay Per Horse"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value={billing === 'monthly'
                        ? env.stripe_barn_management_monthly_plan
                        : env.stripe_barn_management_annual_plan}
                      control={<Radio />}
                      label="Questri Pro"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    You will not be charged. This will define which features you
                    get access to during your trial period.
                  </FormHelperText>
                </FormControl>
                <Box
                  sx={{
                    mt: 2,
                    mb: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <FormLabel id="demo-form-control-label-placement">How did you hear about Questri?</FormLabel>

                  <Select
                    value={howDidYouHear}
                    size="small"
                    onChange={(e) => {
                      setHowDidYouHear(e.target.value);
                    }}
                  >
                    <MenuItem value="Tell us how you heard about Questri!">Tell us how you heard about Questri!</MenuItem>
                    <MenuItem value="Search Engine (Google, App Store, etc)">Search Engine (Google, App Store, etc)</MenuItem>
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="From a friend or colleague">From a friend or colleague</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {
                    howDidYouHear === 'Other' && (
                      <TextField
                        sx={{ mt: 2 }}
                        size="small"
                        label="Other - Tell us how you heard about Questri"
                        fullWidth
                        value={howDidYouHearOther}
                        onChange={(e) => { setHowDidYouHearOther(e.target.value); }}
                      />
                    )
                  }
                </Box>
              </Grid>
              {
                !!nameTakenError
                && (
                  <Grid item xs={12}>
                    <Alert severity="error">{nameTakenError}</Alert>
                  </Grid>
                )
              }
              <Grid item xs={12} align="right">
                <Button
                  onClick={() => { setTenant(defaultTenant); setValidation(defaultValidation); onCancel() }}
                  disabled={isFetching}
                  sx={{ marginRight: 2 }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={addTenant}
                  color="primary"
                  variant="contained"
                  disabled={!isValid() || isFetching}
                >
                  Create Questri Barn
                </Button>
                <Typography variant="caption" component="div" color="primary">
                  &#42;No credit cards required
                </Typography>
              </Grid>
              {
                isFetching
                && (
                  <Box
                    sx={{
                      margin: 2,
                      width: '100%',
                    }}
                  >
                    <Typography variant="caption" component="div">Setting up your Questri Barn...</Typography>
                    <LinearProgress />
                  </Box>
                )
              }

            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 20 }}><FAQCard /></Box>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        open={showPricing}
        onClose={() => setShowPricing(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box
          sx={{
            bgcolor: 'rgb(244, 244, 244)',
            p: 2,
          }}
        >
          <PricingPlans
            onChangeBilling={(newBilling) => setBilling(newBilling)}
            onSelectPlan={(stripePlan) => {
              setTenant({
                ...tenant,
                stripePlan,
              }); setShowPricing(false);
            }}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

CreateOrganizationComponent.propTypes = { onCancel: PropTypes.func };

CreateOrganizationComponent.defaultProps = { onCancel: () => { } };

export default CreateOrganizationComponent;
