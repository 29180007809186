/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
import { EVENT_SUCCESS } from '@pingum/app-state-management/event/types';
import { uniqBy } from 'lodash';
import { formatDateString } from '../../utils/dateHelper';
import * as types from './types';
import * as horseTypes from '../horse/types';
import * as scheduleTypes from '../schedule/types';
import { generateEventsFromSchedules } from '../../utils/scheduleHelper';
import {
  getMappedHorseSuggestions,
  top5MostFrequent,
} from '../../utils/barnBoardHelper';

const upsertItemList = (items, item) => {
  const itemExists = items.find((t) => t.id === item.id);
  if (itemExists) {
    return uniqBy(items.map((t) => (t.id === item.id
      ? JSON.parse(JSON.stringify(item))
      : t)), 'id');
  }
  return uniqBy([...items, item], 'id');
};

const itemInFilter = (task, tagConditions, startDate, endDate) => {
  // tags filter
  if (tagConditions
    && tagConditions.length !== 0 && task.tags) {
    for (let i = 0; i < tagConditions.length; i += 1) {
      if (tagConditions[i].length !== 0) {
        let foundMatchingTag = false;
        for (let j = 0; j < task.tags.length; j += 1) {
          if (tagConditions[i].indexOf(task.tags[j].id) >= 0) {
            foundMatchingTag = true;
            break;
          }
        }
        if (!foundMatchingTag) {
          return false;
        }
      }
    }
  }

  // dates filter
  if (startDate || endDate) {
    const start = startDate
      ? new Date(startDate)
      : new Date(0);
    const end = endDate
      ? new Date(endDate)
      : new Date(8640000000000000);
    if ((new Date(task.startDate).getTime() >= start.getTime()
      && new Date(task.startDate).getTime() < end.getTime())
      || (!task.endDate && new Date(task.startDate).getTime() < start.getTime())
      || (task.endDate && new Date(task.startDate).getTime() < start.getTime() && new Date(task.endDate).getTime() > start.getTime())) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export default (state = {
  startDate: new Date(),
  endDate: new Date(),
  tagConditions: [],
  lastUpdatedDate: new Date(),
  location: null,
  barnHorses: [], // we won't page for the barn board since we need a full list
  tasks: [], // we won't page for the barn board since we need a full list
  incompleteTasks: [], // still active tasks before date range
  events: [], // all events scheduled for date range
  schedules: [],
  scheduleExceptions: [],
  isFetchingBoard: false,
  errorMessage: null,
  realTimeUpdateError: '',
  boardSearchKey: '', // A key (startDate + endDate + locationId) to tell us if horse data needs to be loaded again
  isFetchingBoardSuggestions: false,
  upcomingDueDates: [],
  horseSuggestions: [],
  mostUsedTaskTypes: [],
}, action = {}) => {
  switch (action.type) {
    case types.GET_BOARD_SUGGESTION_REQUESTED:
      return {
        ...state,
        isFetchingBoardSuggestions: true,
        upcomingDueDates: [],
        horseSuggestions: [],
      };
    case types.GET_BOARD_SUGGESTION_SUCCESS:
      return {
        ...state,
        isFetchingBoardSuggestions: false,
        upcomingDueDates: action.upcomingDueDates,
        horseSuggestions: action.horseSuggestions,
        mappedHorseSuggestions: getMappedHorseSuggestions(action.horseSuggestions),
        mostUsedTaskTypes: top5MostFrequent(action.horseSuggestions),
      };
    case types.GET_BOARD_SUGGESTION_ERROR:
      return {
        ...state,
        isFetchingBoardSuggestions: false,
        upcomingDueDates: [],
        horseSuggestions: [],
        mostUsedTaskTypes: [],
      };
    case horseTypes.UPDATE_HORSE_SUGGESTION_SUCCESS:
      if (state.mappedHorseSuggestions
        && state.mappedHorseSuggestions[action.updatedHorseSuggestion.pingumTagId] && action.updatedHorseSuggestion.hideAllSuggestions) {
        return {
          ...state,
          mappedHorseSuggestions: {
            ...state.mappedHorseSuggestions,
            [action.updatedHorseSuggestion.pingumTagId]: null,
          },
        }
      }
      return state;
    case types.GET_BOARD_REQUESTED:
      return {
        ...state,
        isFetchingBoard: true,
        errorMessage: null,
        tagConditions: [],
        startDate: action.startDate,
        endDate: action.endDate,
        realTimeUpdateError: '',
        tasks: [],
        incompleteTasks: [],
        events: [],
      };
    case types.GET_BOARD_SUCCESS:
      return {
        ...state,
        tagConditions: action.tagConditions,
        lastUpdatedDate: new Date(),
        isFetchingBoard: false,
        errorMessage: null,
        barnHorses: action.barnHorses,
        tasks: action.tasks,
        incompleteTasks: action.incompleteTasks,
        events: action.events,
        schedules: action.schedules,
        scheduleExceptions: action.scheduleExceptions,
        boardSearchKey: action.boardSearchKey,
        location: action.location,
      };
    case types.GET_BOARD_ERROR:
      return {
        ...state,
        isFetchingBoard: false,
        errorMessage: action.message || null,
        boardSearchKey: '',
        barnHorses: [],
        location: null,
      };

    case types.BOARD_TASK_UPDATE:
      return {
        ...state,
        lastUpdatedDate: new Date(),
        tasks: (itemInFilter(action.task.event, state.tagConditions, state.startDate, state.endDate)
          ? upsertItemList(state.tasks, action.task.event)
          : state.tasks).filter((t) => !t.archived), // new tasks added should go into todays task list
        incompleteTasks: (itemInFilter(action.task.event, state.tagConditions, null, state.startDate)
          ? state.incompleteTasks.map((t) => (t.id === action.task.event.id
            ? action.task.event
            : t))
          : state.incompleteTasks).filter((t) => !t.archived), // only need to update tasks if they are in incomplete list
      };
    case EVENT_SUCCESS:
      const existsInIncompleteList = state.incompleteTasks.find((t) => t.id === action.event.id);
      return {
        ...state,
        lastUpdatedDate: new Date(),
        tasks: ((!existsInIncompleteList && itemInFilter(action.event, state.tagConditions, state.startDate, state.endDate))
          ? upsertItemList(state.tasks, action.event)
          : state.tasks).filter((t) => !t.archived), // new tasks added should go into todays task list
        incompleteTasks: (itemInFilter(action.event, state.tagConditions, null, state.startDate)
          ? state.incompleteTasks.map((t) => (t.id === action.event.id
            ? action.event
            : t))
          : state.incompleteTasks).filter((t) => !t.archived), // only need to update tasks if they are in incomplete list
      };
    case types.BOARD_TASK_UPDATE_ERROR:
      return {
        ...state,
        realTimeUpdateError: `NOTE: Real time board updates disabled, last updated ${formatDateString(state.lastUpdatedDate)}.`,
      };
    case types.BOARD_SCHEDULE_ADDED_UPDATE:
      // only process if the schedule is in filters and not already in the list of schedules
      if (itemInFilter(action.schedule, state.tagConditions, state.startDate, state.endDate)
        && !state.schedules.find((s) => s.id === action.schedule.id)) {
        const newSchedulesList = upsertItemList(state.schedules, action.schedule)
        return {
          ...state,
          lastUpdatedDate: new Date(),
          schedules: newSchedulesList,
          events: generateEventsFromSchedules(
            newSchedulesList,
            state.scheduleExceptions,
            state.startDate,
            state.endDate,
            action.barnUsersMap,
            action.barnMetadata,
            action.mappedStatuses || {},
          ),
        };
      }
      return { ...state }
    case types.BOARD_SCHEDULE_CHANGED_UPDATE:
      const updatedSchedulesList = state.schedules.map((s) => (s.id !== action.schedule.id
        ? s
        : action.schedule));
      return {
        ...state,
        lastUpdatedDate: new Date(),
        schedules: updatedSchedulesList,
        events: generateEventsFromSchedules(
          updatedSchedulesList,
          state.scheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
      };
    case types.BOARD_SCHEDULE_CANCELED_UPDATE:
      // remove the schedule, or any children schedules if in list
      const updatedRemovedSchedulesList = state.schedules
        .filter((s) => s.id !== action.schedule.id && (!s.parentSchedule || !s.parentSchedule.id || s.parentSchedule.id !== action.schedule.id));
      return {
        ...state,
        lastUpdatedDate: new Date(),
        schedules: updatedRemovedSchedulesList,
        events: generateEventsFromSchedules(
          updatedRemovedSchedulesList,
          state.scheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
      };
    case types.BOARD_SCHEDULE_EXCEPTION_ADDED_UPDATE:
      // only process if the exception is associated with a schedule in the list, and the exception is not already in exception list
      if (state.schedules.find((s) => s.id === action.scheduleException.schedule.id) && !state.scheduleExceptions
        .find((se) => se.id === action.scheduleException.id)) {
        const updatedAddedScheduleExceptionsList = [...state.scheduleExceptions, action.scheduleException];
        return {
          ...state,
          scheduleExceptions: updatedAddedScheduleExceptionsList,
          events: generateEventsFromSchedules(
            state.schedules,
            updatedAddedScheduleExceptionsList,
            state.startDate,
            state.endDate,
            action.barnUsersMap,
            action.barnMetadata,
            action.mappedStatuses || {},
          ),
        };
      }
      return { ...state }
    case types.BOARD_SCHEDULE_EXCEPTION_CHANGED_UPDATE:
      // only process if the exception is in the list
      if (state.scheduleExceptions.find((se) => se.id === action.scheduleException.id)) {
        const updatedExceptionsList = state.scheduleExceptions.map((se) => (se.id !== action.scheduleException.id
          ? se
          : action.scheduleException));
        return {
          ...state,
          scheduleException: updatedExceptionsList,
          events: generateEventsFromSchedules(
            state.schedules,
            updatedExceptionsList,
            state.startDate,
            state.endDate,
            action.barnUsersMap,
            action.barnMetadata,
            action.mappedStatuses || {},
          ),
        };
      }
      return { ...state }
    case types.BOARD_SCHEDULE_EXCEPTION_CANCELED_UPDATE:
      return {
        ...state,
        scheduleExceptions: state.scheduleExceptions.filter((se) => se.id !== action.scheduleException.id),
        events: generateEventsFromSchedules(
          state.schedules,
          state.scheduleExceptions.filter((se) => se.id !== action.scheduleException.id),
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
      };
    case horseTypes.ADD_HORSE_NOTE_SUCCESS:
      if (action.pinToHorse) {
        return {
          ...state,
          barnHorses: state.barnHorses.map((bh) => (bh.horse.id === action.horseId
            ? {
              ...bh,
              horse: {
                ...bh.horse,
                activeNote: action.newHorseNote,
              },
            }
            : bh)),
        };
      }
      return { ...state };
    case horseTypes.UPDATE_HORSE_NOTE_SUCCESS:
      if (!action.pinToHorse) {
        return {
          ...state,
          barnHorses: state.barnHorses.map((bh) => (bh.horse.id === action.horseId
            ? {
              ...bh,
              horse: {
                ...bh.horse,
                activeNote: (action.pinToHorse === false && bh.horse.activeNote && bh.horse.activeNote.id === action.updatedHorseNote.id)
                  ? null
                  : bh.horse.activeNote,
              },
            }
            : bh)),
        };
      }
      return {
        ...state,
        barnHorses: state.barnHorses.map((bh) => (bh.horse.id === action.horseId
          ? {
            ...bh,
            horse: {
              ...bh.horse,
              activeNote: (action.pinToHorse)
                ? action.updatedHorseNote
                : bh.horse.activeNote,
            },
          }
          : bh)),
      };
    case horseTypes.DELETE_HORSE_NOTE_SUCCESS:
      return {
        ...state,
        barnHorses: state.barnHorses.map((bh) => (bh.horse.id === action.horseId
          ? {
            ...bh,
            horse: {
              ...bh.horse,
              activeNote: bh.horse.activeNote && bh.horse.activeNote.id === action.deletedHorseNoteId
                ? null
                : bh.horse.activeNote,
            },
          }
          : bh)),
      };

    /* SCHEDULE UPDATES */
    case scheduleTypes.ADD_SCHEDULE_SUCCESS:
      const newSchedules = state.schedules.find((s) => s.id === action.newSchedule.id)
        ? state.schedules
        : [...state.schedules, action.newSchedule]; // only add if not already in the list
      return {
        ...state,
        schedules: newSchedules,
        lastUpdatedDate: new Date(),
        events: generateEventsFromSchedules(
          newSchedules,
          state.scheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
        ),
        mostUsedTaskTypes: [...state.mostUsedTaskTypes, action.newSchedule.eventTemplate.id].slice(0, 6), // add most recently used to list
      };
    case scheduleTypes.UPDATE_SCHEDULE_SUCCESS:
      const updatedSchedules = state.schedules.map((f) => (f.id === action.updatedSchedule.id
        ? action.updatedSchedule
        : f));
      return {
        ...state,
        lastUpdatedDate: new Date(),
        events: generateEventsFromSchedules(
          updatedSchedules,
          state.scheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
        schedules: updatedSchedules,

      };
    case scheduleTypes.DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        lastUpdatedDate: new Date(),
        events: state.events.filter((e) => e.resource.id !== action.deletedScheduleId
          && (!e.resource.parentSchedule || e.resource.parentSchedule.id !== action.deletedScheduleId)),
        schedules: state.schedules.filter((f) => f.id !== action.deletedScheduleId
          && (!f.parentschedule || f.parentSchedule.id !== action.deletedScheduleId)),
      };
    case scheduleTypes.ADD_SCHEDULE_EXCEPTION_SUCCESS:
      // only add if not already in the list
      if (!state.scheduleExceptions.find((se) => se.id === action.newScheduleException.id)) {
        const newScheduleExceptions = [...state.scheduleExceptions, action.newScheduleException];
        const newSchedulesFromException = action.newChildSchedule
          ? [...state.schedules, action.newChildSchedule]
          : state.schedules;
        return {
          ...state,
          lastUpdatedDate: new Date(),
          schedules: newSchedulesFromException,
          events: generateEventsFromSchedules(
            newSchedulesFromException,
            newScheduleExceptions,
            state.startDate,
            state.endDate,
            action.barnUsersMap,
            action.barnMetadata,
            action.mappedStatuses || {},
          ),
          scheduleExceptions: newScheduleExceptions,
        };
      }
      return { ...state }
    case scheduleTypes.DELETE_SCHEDULE_EXCEPTION_SUCCESS:
      const newScheduleExceptions2 = state.scheduleExceptions.filter((f) => f.id !== action.deletedScheduleExeptionId);
      return {
        ...state,
        lastUpdatedDate: new Date(),
        events: generateEventsFromSchedules(
          state.schedules,
          newScheduleExceptions2,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
        scheduleExceptions: newScheduleExceptions2,
      };
    default:
      return state;
  }
};
