// import { LocationService } from '@pingum/api-services';
import LocationService from '../../services/LocationService';
import * as types from './types';
import {
  dispatchNetworkRequest,
  dispatchNetworkRequestDynamicSuccessDispatch,
} from '../networkDispatcher';
import { setTimezones } from '../app/actions';
import HorseLocationService from '../../services/HorseLocationService';

export const getAllPagedHorseLocationData = async (page, pageSize, sort, date, endDate, allContent = []) => {
  const response = await HorseLocationService.getHorseLocations(page, pageSize, sort, date, endDate);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedHorseLocationData(page + 1, pageSize, sort, date, endDate, newAllContent);
};

export const getAllPagedLocationData = async (page, pageSize, sort, search, allContent = []) => {
  const response = await LocationService.getLocations(page, pageSize, sort, search);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedLocationData(page + 1, pageSize, sort, search, newAllContent);
};

export const getAllHorseLocations = (date = new Date(), endDate = null) => (dispatch) => {
  const promise = getAllPagedHorseLocationData(0, 100, '', date, endDate);

  promise.then((data) => {
    dispatch({
      type: types.GET_ALL_HORSE_LOCATIONS_SUCCESS,
      horseLocations: data,
    });
  });
  return promise;
};

export const getAllLocations = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_LOCATIONS_REQUESTED });

  const promise = getAllPagedLocationData(0, 100, '', '');

  promise.then((data) => {
    dispatch({
      type: types.GET_ALL_LOCATIONS_SUCCESS,
      locations: data,
    });
    dispatch(getAllHorseLocations());
  });
  return promise;
};

export const getLocations = (page = 0, pageSize = 25, sort = '', search = '') => (dispatch) => {
  const promise = LocationService.getLocations(page, pageSize, sort, search);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_LOCATION_REQUESTED,
    successType: types.GET_LOCATION_SUCCESS,
    errorType: types.GET_LOCATION_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading locations for this organization',
    responseName: 'locations',
  });
  return promise;
};

export const getLocationHorses = (locationId, page = 0, pageSize = 25, sort = '', date = new Date()) => (dispatch) => {
  const promise = LocationService.getLocationHorses(locationId, page, pageSize, sort, date);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_LOCATION_HORSES_REQUESTED,
    successType: types.GET_LOCATION_HORSES_SUCCESS,
    errorType: types.GET_LOCATION_HORSES_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading horses at this location',
    responseName: 'locationHorses',
  });
  return promise;
};

export const addLocation = (request) => (dispatch) => {
  const promise = LocationService.addLocation(request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.ADD_LOCATION_REQUESTED,
    successType: types.ADD_LOCATION_SUCCESS,
    errorType: types.ADD_LOCATION_ERROR,
    promise,
    successMessage: 'Successfully created the location',
    defaultErrorMessage: 'There was a problem adding the location to the organization',
    responseName: 'newLocation',
  });
  return promise;
};

export const updateLocation = (id, request) => (dispatch) => {
  const promise = LocationService.updateLocation(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.UPDATE_LOCATION_REQUESTED,
    successType: types.UPDATE_LOCATION_SUCCESS,
    errorType: types.UPDATE_LOCATION_ERROR,
    promise,
    successMessage: 'Successfully updated the location',
    defaultErrorMessage: 'There was a problem updating the location',
    responseName: 'updatedLocation',
  });
  return promise;
};

export const deleteLocation = (id) => (dispatch) => {
  const promise = LocationService.deleteLocation(id);
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.DELETE_LOCATION_REQUESTED,
    successDispatch: {
      type: types.DELETE_LOCATION_SUCCESS,
      deletedLocationId: id,
    },
    errorType: types.DELETE_LOCATION_ERROR,
    promise,
    successMessage: 'Successfully deleted the location',
    defaultErrorMessage: 'There was a problem deleting the location',
    responseName: 'response',
  });
  return promise;
};

export const setSelectedLocation = (location) => (dispatch) => {
  if (location && location.timezone) {
    dispatch(setTimezones(location.timezone));
  }
  dispatch({
    type: types.SELECTED_LOCATION_CHANGED,
    selectedLocation: location,
  })
};

export const getLocationHorseNotes = (id, request) => (dispatch) => {
  const promise = LocationService.getLocationHorseNotes(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_LOCATION_HORSE_NOTES_REQUESTED,
    successType: types.GET_LOCATION_HORSE_NOTES_SUCCESS,
    errorType: types.GET_LOCATION_HORSE_NOTES_ERROR,
    promise,
    successMessage: 'Successfully loaded horse notes',
    defaultErrorMessage: 'There was a problem loading horse notes',
    responseName: 'horseNotes',
  });
  return promise;
};

export const getLocationHorseFeeds = (id, request) => (dispatch) => {
  const promise = LocationService.getLocationHorseFeeds(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_LOCATION_HORSE_FEEDS_REQUESTED,
    successType: types.GET_LOCATION_HORSE_FEEDS_SUCCESS,
    errorType: types.GET_LOCATION_HORSE_FEEDS_ERROR,
    promise,
    successMessage: 'Successfully loaded horse feeds',
    defaultErrorMessage: 'There was a problem loading horse feeds',
    responseName: 'horseFeeds',
  });
  return promise;
};
