import * as React from 'react';
import PropTypes from 'prop-types';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { addTenantUserRequest } from '@pingum/app-state-management/tenant/actions';

import SuccessContainer from '../../TaskDetail/SuccessContainer';
import ErrorContainer from '../../TaskDetail/ErrorContainer';
import { getTenantFromUrl } from '../../../utils/urlHelper';
import accessDenied from '../../../images/unused-undraw-illustrations/undraw_access_denied_re_awnf.svg';
import account from '../../../images/unused-undraw-illustrations/account.svg';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
};
const dangerIconStyles = {
  width: 250,
  display: 'block',
  margin: 'auto',
  marginBottom: '1rem',
};

const AccessDeniedCardComponent = ({ tenantUrl }) => {
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state) => state.tenant);
  const {
    checkEmailUser,
    organizationAuth0UserLimitReached,
  } = useSelector((state) => state.user);
  const {
    user,
    logout,
  } = useAuth0();

  return (
    <Box sx={{ mt: 2 }}>
      {/* Snackbars cannot be seen over a dialog so we need to place it here */}
      <SuccessContainer />
      <ErrorContainer />
      {
        (checkEmailUser && checkEmailUser.id && !organizationAuth0UserLimitReached)
          ? (
            <Card style={defaultCardStyle}>
              <CardHeader title="Oops, Wrong Account?" />
              <CardContent>
                <img src={account} alt="man access account" style={dangerIconStyles} />
                <Typography variant="body1" align="center" gutterBottom>
                  Did you login to the wrong account? It looks like you have an existing account with
                  this Questri Barn. This can happen if you logged in with a social provider
                  like google or apple but were setup with a username and password account (or vice versa).
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                  To get access to <strong><a href={tenantUrl}>{tenantUrl}</a></strong> log into
                  the correct account you were signed up with for this Questri Barn.
                </Typography>
                <div style={buttonContainer}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => logout({ returnTo: `${window.location.origin}` })}
                    disabled={isFetching}
                    variant="contained"
                  >
                    Logout and Log Into Correct Account
                  </Button>
                </div>
              </CardContent>
            </Card>
          )
          : (
            <Card style={defaultCardStyle}>
              <CardHeader title="Access Denied" />
              <CardContent>
                <img src={accessDenied} alt="man access denied app" style={dangerIconStyles} />
                <Typography variant="subtitle1" align="center" gutterBottom>
                  You do not have access to the page and Questri Barn you were trying to reach.
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                  Please make sure you have access to
                  <br />
                  <strong><a href={tenantUrl}>{tenantUrl}</a></strong>.
                </Typography>
                <div style={buttonContainer}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    href="/logout"
                    disabled={isFetching}
                    style={{ marginRight: '1rem' }}
                  >
                    Return Home
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => dispatch(addTenantUserRequest(getTenantFromUrl(), {
                      auth0UserId: user.sub,
                      email: user.email,
                      firstName: user.given_name || '',
                      lastName: user.family_name || '',
                      passive: false,
                    }))}
                    disabled={isFetching}
                    variant="contained"
                  >
                    Request Access To Questri Barn
                  </Button>
                </div>
              </CardContent>
            </Card>
          )
      }

    </Box>
  );
};
AccessDeniedCardComponent.propTypes = { tenantUrl: PropTypes.string.isRequired };

AccessDeniedCardComponent.defaultProps = {};

export default AccessDeniedCardComponent;
