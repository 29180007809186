import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';
import Chip from '../Chip';

import { getColorForImportance } from '../../utils/enumHelper';
import styles from './styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `1px solid ${theme.palette.background.paper}`,
    width: '10px',
    height: '10px',
  },
}));

export const getHorsePictureUrl = (horse) => (
  `https://${horse.picture
    ? `${horse.picture}?cacheBust=${new Date()}`
    : `i0.wp.com/cdn.auth0.com/avatars/${horse.name.charAt(0).toLowerCase()}.png`}`
)

const HorseComonent = ({
  bordered,
  chip,
  horse,
  align,
  classes,
  hideName,
  hideShowName,
  size,
  chipSize,
  onClick,
  clickable,
  variant,
  flexDirection,
  textWidth,
  ...rest
}) => {
  const picture = getHorsePictureUrl(horse);

  const avatar = (
    <StyledBadge
      color={getColorForImportance(horse.activeNote
        ? horse.activeNote.importance
        : 'https://i.imgur.com/tHcI4iD.png')}
      overlap="circular"
      variant="dot"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      invisible={!horse.activeNote}
    >
      <Avatar
        alt={horse.name}
        src={picture}
        onClick={onClick}
        sx={{
          color: 'action.active',
          width: chip
            ? (((chipSize === 'l' || chipSize === 'xl') && 24) || 18)
            : size,
          height: chip
            ? (((chipSize === 'l' || chipSize === 'xl') && 24) || 18)
            : size,
          cursor: clickable
            ? 'pointer'
            : 'default',
        }}
      />
    </StyledBadge>
  );

  if (hideName) {
    return avatar;
  }

  return (
    !chip
      ? (
        <Box
          onClick={onClick}
          sx={{
            display: 'flex',
            alignItems: (flexDirection && flexDirection === 'column')
              ? 'flex-start'
              : 'center',
            justifyContent: align,
            flexDirection,
            cursor: clickable
              ? 'pointer'
              : 'default',
          }}
        >
          {avatar}
          <Box
            sx={{
              ml: (flexDirection && flexDirection === 'column')
                ? 0
                : 1,
              width: textWidth || 'inherit',
              maxWidth: 200,
            }}
          >
            <Typography component="h3" noWrap className={classes.horseName}>{horse.name}</Typography>
            {horse.showName && <Typography noWrap component="div" className={classes.horseShowName}>{horse.showName}</Typography>}
          </Box>
        </Box>
      )
      : (
        <Chip
          avatar={avatar}
          label={horse.name}
          disableGutters
          showDelete={false}
          variant={variant}
          size={((chipSize === 'l' || chipSize === 'xl') && 'large') || 'small'}
          color={horse.activeNote
            ? getColorForImportance(horse.activeNote.importance)
            : 'default'}
          clickable={clickable}
          onClick={(e) => {
            e.stopPropagation();
            onClick()
          }}
          {...rest}
        />
      )
  );
};

HorseComonent.propTypes = {
  bordered: PropTypes.bool,
  chip: PropTypes.bool,
  align: PropTypes.string,
  horse: PropTypes.object.isRequired,
  classes: PropTypes.object,
  hideName: PropTypes.bool,
  hideShowName: PropTypes.bool,
  size: PropTypes.any,
  chipSize: PropTypes.string,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  variant: PropTypes.string,
  flexDirection: PropTypes.string,
  textWidth: PropTypes.number,
};

HorseComonent.defaultProps = {
  bordered: false,
  align: 'flex-start',
  chip: false,
  classes: {},
  hideName: false,
  hideShowName: false,
  size: 40,
  onClick: null,
  clickable: false,
  variant: 'outlined',
  flexDirection: 'row',
  textWidth: null,
  chipSize: null,
};

export default withStyles(styles)(HorseComonent);
