import * as React from 'react';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCard from '@mui/icons-material/AddCard';
import accessDenied from '../../../images/unused-undraw-illustrations/undraw_access_denied_re_awnf.svg';
import FAQCard from '../../../pages/settings/AdminSettingsPage/components/FAQCard';
import StripePortalButton from '../../StripePortalButton';
import StripeCheckoutButton from '../../StripeCheckoutButton';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
  textAlign: 'center',
};
const dangerIconStyles = {
  width: 150,
  display: 'block',
  margin: 'auto',
  marginBottom: '2rem',
};

const DeactivatedTenantCardComponent = () => {
  const { tenant } = useSelector((state) => state.tenant);

  const { user } = useAuth0();

  const roles = (user && user['https://pingum.io/tenants'] && user['https://pingum.io/tenants'][tenant.name] && user['https://pingum.io/tenants'][tenant.name].roles) || []

  return (
    <Box sx={{ mt: 2 }}>
      <Card style={defaultCardStyle}>
        <CardHeader title="Questri Barn Deactivated" />
        <CardContent sx={{ pt: 0 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 300,
              mb: 2,
            }}
            gutterBottom
          >
            This Questri Barn is currently deactivated. An
            owner of this account can reactivate the Questri Barn by
            subscribing to a paid plan and providing a valid payment method.
          </Typography>
          <Typography component="div" gutterBottom sx={{ mb: 4 }}>
            <div><b>Deactivation Date: </b> {moment(tenant.deactivationDate).format('ll')}</div>
            {
              (tenant.deactivatedReason && tenant.deactivatedReason.length > 0) && (
                <div><b>Deactivation Reason:  </b> {tenant.deactivatedReason}</div>
              )
            }
          </Typography>
          <img src={accessDenied} alt="man access account" style={dangerIconStyles} />
          {
            roles.indexOf('owner') >= 0 && (
              tenant.stripeSubscriptionId
                ? (
                  <div style={buttonContainer}>
                    <StripePortalButton tenant={tenant} buttonText="Manage Questri Subscription" startIcon={<AddCard />} variant="contained" sx={{ mb: 2 }} size="large" />
                    <Typography component="div" sx={{ fontWeight: 300 }} variant="caption" align="center">
                      You will be redirected to our secure portal for manging your billing and payment settings.
                    </Typography>
                  </div>
                )
                : (
                  <div style={buttonContainer}>
                    <StripeCheckoutButton tenant={tenant} startIcon={<AddCard />} variant="contained" sx={{ mb: 2 }} size="large" buttonText="Reactivate Questri" />
                    <Typography component="div" sx={{ fontWeight: 300 }} variant="caption" align="center">
                      You will need to subscribe to Questri to reactivate your account.
                    </Typography>
                  </div>
                )
            )
          }
          <Divider
            sx={{
              mt: 1,
              mb: 1,
            }}
          />

          <Typography variant="body1" align="center" gutterBottom>
            If you have any questions or need support, you can contact support@questri.app
          </Typography>
        </CardContent>
      </Card>

      {
        roles.indexOf('owner') >= 0 && (
          <Box sx={{ mt: 4 }}><FAQCard /></Box>
        )
      }
    </Box>
  );
};
DeactivatedTenantCardComponent.propTypes = {};

DeactivatedTenantCardComponent.defaultProps = {};

export default DeactivatedTenantCardComponent;
