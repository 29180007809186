import { groupBy } from 'lodash';
import * as types from './types';
import * as userTypes from '../user/types';
import * as horseTypes from '../horse/types';
import { getTenantFromUrl } from '../../utils/urlHelper';

const defaultReferenceNumbers = [
  'Breed Registration Number', 'FEI Number', 'Measurement Card Number', 'Passport Number', 'USHJA Number',
];

export default (state = {
  barns: [],
  barnUsers: [],
  barnUsersMap: {},
  selectedBarn: null,
  isFetchingBarns: false,
  isFetchingBarnUsers: false,
  isUpdatingBarnUsers: false,
  barnHorses: { content: [] },
  barnHorsesMap: {},
  allBarnHorses: [],
  referenceNumbers: [], // list of all the different reference numbers used by this organization
  isFetchingAllBarnHorses: false,
  isFetchingBarnHorses: false,
  isFetchingBarnHorse: false,
  barnNotices: {
    missingLocations: [],
    missingFeed: [],
    horseLodgingRequests: [],
  },
  isFetchingBarnNotices: false,
  barnManagementSubscription: {
    active: true,
    status: 'Active',
    statusComment: '',
    freeTrialEndDate: new Date(new Date().getTime() + 1209600000),
    stripeSubscription: null,
  },
}, action = {}) => {
  switch (action.type) {
    case types.GET_BARNS_REQUESTED:
      return {
        ...state,
        barns: [],
        isFetchingBarns: true,
      };
    case types.GET_BARNS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const foundBarn = action.barns.filter((barn) => barn.tenant === getTenantFromUrl()).length > 0
        ? action.barns.filter((barn) => barn.tenant === getTenantFromUrl())[0]
        : null;
      return {
        ...state,
        isFetchingBarns: false,
        barns: action.barns,
        selectedBarn: !foundBarn
          ? null
          : {
            ...foundBarn,
            barnMetadata: {
              ...foundBarn.barnMetadata,
              // TAG TYPES
              taskTypeTagTypeId: parseInt(foundBarn.barnMetadata.taskTypeTagTypeId, 10),
              horseTagTypeId: parseInt(foundBarn.barnMetadata.horseTagTypeId, 10),
              locationTagTypeId: parseInt(foundBarn.barnMetadata.locationTagTypeId, 10),
              tagsTagTypeId: parseInt(foundBarn.barnMetadata.tagsTagTypeId, 10),
              healthTagTypeId: parseInt(foundBarn.barnMetadata.healthTagTypeId, 10),
              // TAGS (task type)
              horseTaskTypeTagId: parseInt(foundBarn.barnMetadata.horseTaskTypeTagId, 10),
              barnTaskTypeTagId: parseInt(foundBarn.barnMetadata.barnTaskTypeTagId, 10),
              eventTaskTypeTagId: parseInt(foundBarn.barnMetadata.eventTaskTypeTagId, 10),
              farrierTagId: parseInt(foundBarn.barnMetadata.farrierTagId, 10),
              healthTagId: parseInt(foundBarn.barnMetadata.healthTagId, 10),
              // EVENT DATA
              calendarDisplayColorEventDataId: parseInt(foundBarn.barnMetadata.calendarDisplayColorEventDataId, 10),
              clientViewableEventDataId: parseInt(foundBarn.barnMetadata.clientViewableEventDataId, 10),
              horseBillablePriceEventDataId: parseInt(foundBarn.barnMetadata.horseBillablePriceEventDataId, 10),
              horseNoteEventDataId: parseInt(foundBarn.barnMetadata.horseNoteEventDataId, 10),
              horseNoteTypeEventDataId: parseInt(foundBarn.barnMetadata.horseNoteTypeEventDataId, 10),
              horseNoteImportanceEventDataId: parseInt(foundBarn.barnMetadata.horseNoteImportanceEventDataId, 10),
              horseNoteClientViewableEventDataId: parseInt(foundBarn.barnMetadata.horseNoteClientViewableEventDataId, 10),
              // STATUSES
              statusEndId: parseInt(foundBarn.barnMetadata.statusEndId, 10),
              statusPendingId: parseInt(foundBarn.barnMetadata.statusPendingId, 10),
              statusStartId: parseInt(foundBarn.barnMetadata.statusStartId, 10),
            },
          },
      };
    case types.GET_BARNS_ERROR:
      return {
        ...state,
        isFetchingBarns: false,
        selectedBarn: null,
      };
    case types.GET_BARN_NOTICES_REQUESTED:
      return {
        ...state,
        barnNotices: {
          missingLocations: [],
          missingFeed: [],
          horseLodgingRequests: [],
        },
        isFetchingBarnNotices: true,
      };
    case types.GET_BARN_NOTICES_SUCCESS:
      return {
        ...state,
        isFetchingBarnNotices: false,
        barnNotices: action.barnNotices,
      };
    case types.GET_BARN_NOTICES_ERROR:
      return {
        ...state,
        isFetchingBarnNotices: false,
      };
    case types.GET_BARN_HORSES_REQUESTED:
      return {
        ...state,
        barnHorses: { content: [] },
        isFetchingBarnHorses: true,
      };
    case types.GET_BARN_HORSES_SUCCESS:
      return {
        ...state,
        isFetchingBarnHorses: false,
        barnHorses: action.barnHorses,
      };
    case types.GET_BARN_HORSES_ERROR:
      return {
        ...state,
        isFetchingBarnHorses: false,
      };
    case types.GET_ALL_BARN_HORSES_REQUESTED:
      return {
        ...state,
        barnHorsesMap: {},
        allBarnHorses: [],
        isFetchingAllBarnHorses: true,
      };
    case types.GET_ALL_BARN_HORSES_SUCCESS:
      return {
        ...state,
        isFetchingAllBarnHorses: false,
        allBarnHorses: action.barnHorses,
        barnHorsesMap: {
          ...groupBy(action.barnHorses, 'pingumTagId'),
          ...groupBy(action.barnHorses, 'id'),
          ...groupBy(action.barnHorses, 'horse.id'),
        },
        referenceNumbers:
          [...new Set([...action.barnHorses.map((h) => Object.keys(h.horse.referenceNumbers || {})).flat(), ...defaultReferenceNumbers])].sort(),
      };
    case types.GET_ALL_BARN_HORSES_ERROR:
      return {
        ...state,
        isFetchingAllBarnHorses: false,
      };
    case horseTypes.UPDATE_HORSE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedAllBarnHorses = state.allBarnHorses.map((bh) => (bh.horse.id === action.updatedHorse.id
        ? {
          ...bh,
          horse: action.updatedHorse,
        }
        : bh));
      return {
        ...state,
        allBarnHorses: updatedAllBarnHorses,
        barnHorsesMap: {
          ...groupBy(updatedAllBarnHorses, 'pingumTagId'),
          ...groupBy(updatedAllBarnHorses, 'id'),
          ...groupBy(updatedAllBarnHorses, 'horse.id'),
        },
      };
    case types.UPDATE_BARN_HORSE_REQUESTED:
      return {
        ...state,
        isFetchingBarnHorse: true,
      };
    case types.UPDATE_BARN_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingBarnHorse: false,
        barnHorses: {
          ...state.barnHorses,
          content: state.barnHorses.content.map((bh) => (bh.id === action.updatedBarnHorse.id
            ? action.updatedBarnHorse
            : bh)),
        },
        allBarnHorses: state.allBarnHorses.map((bh) => (bh.id === action.updatedBarnHorse.id
          ? action.updatedBarnHorse
          : bh)).filter((bh) => !bh.endDate || new Date(bh.endDate).getTime() > new Date().getTime()), // remove any barn horses no longer lodging
      };
    case types.UPDATE_BARN_HORSE_ERROR:
      return {
        ...state,
        isFetchingBarnHorse: false,
      };
    case types.UPDATE_BARN_REQUESTED:
      return {
        ...state,
        isFetchingBarns: true,
      };
    case types.UPDATE_BARN_SUCCESS:
      return {
        ...state,
        isFetchingBarns: false,
        selectedBarn: {
          ...state.selectedBarn,
          displayName: action.updatedBarn.displayName,
          barnMetadata: {
            ...action.updatedBarn.barnMetadata,
            // TAG TYPES
            taskTypeTagTypeId: parseInt(action.updatedBarn.barnMetadata.taskTypeTagTypeId, 10),
            horseTagTypeId: parseInt(action.updatedBarn.barnMetadata.horseTagTypeId, 10),
            locationTagTypeId: parseInt(action.updatedBarn.barnMetadata.locationTagTypeId, 10),
            tagsTagTypeId: parseInt(action.updatedBarn.barnMetadata.tagsTagTypeId, 10),
            healthTagTypeId: parseInt(action.updatedBarn.barnMetadata.healthTagTypeId, 10),
            // TAGS (task type)
            horseTaskTypeTagId: parseInt(action.updatedBarn.barnMetadata.horseTaskTypeTagId, 10),
            barnTaskTypeTagId: parseInt(action.updatedBarn.barnMetadata.barnTaskTypeTagId, 10),
            eventTaskTypeTagId: parseInt(action.updatedBarn.barnMetadata.eventTaskTypeTagId, 10),
            healthTagId: parseInt(action.updatedBarn.barnMetadata.healthTagId, 10),
            farrierTagId: parseInt(action.updatedBarn.barnMetadata.farrierTagId, 10),
            // EVENT DATA
            calendarDisplayColorEventDataId: parseInt(action.updatedBarn.barnMetadata.calendarDisplayColorEventDataId, 10),
            clientViewableEventDataId: parseInt(action.updatedBarn.barnMetadata.clientViewableEventDataId, 10),
            horseBillablePriceEventDataId: parseInt(action.updatedBarn.barnMetadata.horseBillablePriceEventDataId, 10),
            horseNoteEventDataId: parseInt(action.updatedBarn.barnMetadata.horseNoteEventDataId, 10),
            horseNoteTypeEventDataId: parseInt(action.updatedBarn.barnMetadata.horseNoteTypeEventDataId, 10),
            horseNoteImportanceEventDataId: parseInt(action.updatedBarn.barnMetadata.horseNoteImportanceEventDataId, 10),
            horseNoteClientViewableEventDataId: parseInt(action.updatedBarn.barnMetadata.horseNoteClientViewableEventDataId, 10),
            // STATUSES
            statusEndId: parseInt(action.updatedBarn.barnMetadata.statusEndId, 10),
            statusPendingId: parseInt(action.updatedBarn.barnMetadata.statusPendingId, 10),
            statusStartId: parseInt(action.updatedBarn.barnMetadata.statusStartId, 10),
          },
        },
      };
    case types.UPDATE_BARN_ERROR:
      return {
        ...state,
        isFetchingBarns: false,
      };
    case types.GET_BARN_USERS_REQUESTED:
      return {
        ...state,
        isFetchingBarnUsers: false,
        barnUsers: [],
        barnUsersMap: {},
      };
    case types.GET_BARN_USERS_SUCCESS:
      return {
        ...state,
        isFetchingBarnUsers: false,
        barnUsers: action.barnUsers,
        barnUsersMap: {
          ...groupBy(action.barnUsers.filter((bu) => !!bu.id), 'id'),
          ...groupBy(action.barnUsers.filter((bu) => !!bu.auth0UserId), 'auth0UserId'),
          ...groupBy(action.barnUsers.filter((bu) => !!bu.email), 'email'),
          ...groupBy(action.barnUsers.filter((bu) => (!bu.email && !bu.auth0UserId) && bu.userFullName), 'userFullName'), // If non account user, add by full name
        },
      };
    case types.GET_BARN_USERS_ERROR:
      return {
        ...state,
        isFetchingBarnUsers: false,
        barnUsers: [],
        barnUsersMap: {},
      };
    case userTypes.DELETE_USER_REQUESTED:
      return {
        ...state,
        isUpdatingBarnUsers: true,
      };
    case userTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingBarnUsers: false,
        barnUsers: state.barnUsers.filter((bu) => bu.id !== action.deletedBarnUserId),
      };
    case userTypes.DELETE_USER_ERROR:
      return {
        ...state,
        isUpdatingBarnUsers: false,
      };
    case userTypes.UPDATE_USER_REQUESTED:
      return {
        ...state,
        isUpdatingBarnUsers: true,
      };
    case userTypes.UPDATE_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedBarnUsers = state.barnUsers.map((bu) => (bu.id === action.user.id
        ? action.user
        : bu))
      return {
        ...state,
        isUpdatingBarnUsers: false,
        barnUsers: updatedBarnUsers,
        barnUsersMap: {
          ...groupBy(updatedBarnUsers.filter((bu) => !!bu.id), 'id'),
          ...groupBy(updatedBarnUsers.filter((bu) => !!bu.auth0UserId), 'auth0UserId'),
          ...groupBy(updatedBarnUsers.filter((bu) => !!bu.email), 'email'),
        },
      };
    case userTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdatingBarnUsers: false,
      };
    case userTypes.ADD_USER_REQUESTED:
      return {
        ...state,
        isUpdatingBarnUsers: true,
      };
    case userTypes.ADD_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const allBarnUsers = [action.user, ...state.barnUsers];
      return {
        ...state,
        isUpdatingBarnUsers: false,
        barnUsers: allBarnUsers,
        barnUsersMap: {
          ...groupBy(allBarnUsers.filter((bu) => !!bu.id), 'id'),
          ...groupBy(allBarnUsers.filter((bu) => !!bu.auth0UserId), 'auth0UserId'),
          ...groupBy(allBarnUsers.filter((bu) => !!bu.email), 'email'),
        },
      };
    case userTypes.ADD_USER_ERROR:
      return {
        ...state,
        isUpdatingBarnUsers: false,
      };
    case types.SET_QUESTRI_SUBSCRIPTION:
      return {
        ...state,
        questriSubscription: action.questriSubscription,
      }
    default:
      return state;
  }
};
